import { ImagesLiteField } from './ImagesLiteField';
import api from '../../api';
import { Card, CardActionArea, CardContent, makeStyles, Typography } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Fragment } from 'react';

export const FilesField = (props) => {
    const { source, record, mode = null, label=''} = props;
    const classes = useStyles();

    if (mode === 'nb') {
        return (record[source]?.length+ ' fichiers');
    }
    if (record[source]) {

        return (
            <>
                <Typography variant="caption" color={'textSecondary'} style={{marginTop:20}}>{label} :</Typography>
                <div style={{
                    display: 'flex',                
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    {record[source].map((file, key) => (
                        <Fragment key={key}>
                            <Card className={classes.globalCard}>
                                <RenderFile file={file} key={key} />
                            </Card>
                        </Fragment>
                    ))}
                </div>
            </>
        )
    }
    return (<span>User bad format</span>)
}

const RenderFile = ({ file }) => {
    const classes = useStyles();
    
    // console.log(file)
    switch(file.mime) {
        case "application/pdf":
            return (
                <CardActionArea href={api.url+file.url} target={'_blank'} className={classes.cardActionArea}>
                    <center><PictureAsPdfIcon className={classes.fileIcon}/></center>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.fileName}>{file.name}</div>
                    </CardContent>
                </CardActionArea>
                )
        case "image/jpeg":
            return <>
                <ImagesLiteField source="images" record={{images: [file]}} imageStyle={{ height: '200px' }} />
                <CardActionArea href={api.url+file.url} target={'_blank'} className={classes.cardActionArea}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.fileName}>{file.name}</div>
                    </CardContent>
                </CardActionArea>
            </>
        default:
            return (<>
                <CardActionArea href={api.url+file.url} target={'_blank'} className={classes.cardActionArea}>
                    <center><FileCopyIcon className={classes.fileIcon} /></center>
                    <CardContent className={classes.cardContent}>  
                        <div className={classes.fileName}>{file.name}</div>
                    </CardContent>
                </CardActionArea>
                </>
                )
    }
}

const useStyles  = makeStyles(theme => ({
    fileIcon: {fontSize: 40, margin:25, alignContent:'center'},
    cardActionArea: {height: '100%', display:'contents'},
    cardContent: {flex:1, flexWrap:'wrap', alignContent:'center', backgroundColor:'white', position:'absolute', bottom:0, height:60, paddingTop:0},
    fileName: {flex:1, flexWrap:'wrap', width:'100%', overflowWrap:'anywhere', fontSize:14},
    root: {
        display: 'flex',                    
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    globalCard: {
        maxWidth: 200,
        minWidth: 200,
        maxHeight: 200,
        minHeight: 200,
        margin: 10,
        marginLeft:0,
        marginRight: 20,
        overflow: 'hidden',
        borderRadius: 10,
        position:'relative'
    },
    media: {
        height: 140,
    },
}));