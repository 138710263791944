import { ShowButton  } from 'react-admin';
import PropTypes from 'prop-types';
import { ImagesLiteField } from './ImagesLiteField';

export const AlertField = ({ record = {}, source = false, showButton=true}) => {
    // console.log(record);
    if (!source) {
        return(<span>
            <ShowButton basePath="/alerts" label="Show" record={record} />
        </span>);
    } else {
        return(<span>
            {record[source]?.id ?(<span>Alerte #{record[source].id}</span>):(<></>) }<br />
            {record[source]?.id ?(<span>Code Postal :{record[source]?.postcode}</span>):(<></>) }<br />
            <ImagesLiteField source="images" record={record[source]} /><br />
            {showButton && <ShowButton basePath="/alerts" label="Show" record={{id:(record[source]?.id ||record[source]) }} />}
        </span>);
    }
    
}

AlertField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};