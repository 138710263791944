import React, { useContext, useState }      from "react";
import { Link }                   from 'react-router-dom';
import { useSelector }            from 'react-redux'; 
import { GridShowLayout, RaGrid } from 'ra-compact-ui'
import PersonIcon                 from '@material-ui/icons/Person';
import ScheduleIcon               from '@material-ui/icons/Schedule';
import PinDropIcon                from '@material-ui/icons/PinDrop';
import DescriptionIcon            from '@material-ui/icons/Description';
import LockIcon                   from '@material-ui/icons/Lock';
import VerifiedUserIcon           from '@material-ui/icons/VerifiedUser';

import {
  TextField,
  Show,
  TopToolbar,
  useRecordContext,
  DateField,
  FunctionField,
  TabbedShowLayout,
  Tab,
  ResourceContextProvider,
  EditButton,
  List,
  Datagrid,
  Pagination,
  ShowButton,
  useRefresh
} from "react-admin";
import {
  Grid,
  Box,
  makeStyles,
  Button, 
  CardHeader,
  Card,
  CardContent,
  Paper,
  Typography
} from "@material-ui/core";

import api, {fetchToken} from '../../../api'

import AddEventDialog from                "./AddEventDialog"
import AddCommentDialog from              "./AddCommentDialog"

import { translateState } from            "../../../helpers/Enum";

import { 
  UserField, 
  LocalisationField 
} from                                    "../../Field/UserField";
import { AlertStatusField } from          "../../Field/AlertStatusField";
import { MapField } from                  "../../Field/MapField";
import { AlertImagesGalleryField } from   "../../Field/AlertImagesGalleryField";
import { OfflineContext } from            "../../Layout/AdminOfflineLayout";
import { ShowDetailQuantity } from        "../../UI/DetailQuantity";

import { AlertCommentListFromAlert } from "../AlertComment";
import { AlertEventListFromAlert } from   "../AlertEvent/AlertEventListFromAlert";
import { AlertEventEditForm } from        "../AlertEvent/AlertEventEdit";
import { useEffect } from "react";
import { DescriptionGeneratorModal } from "../../UI/DescriptionGeneratorModal";
import { TextLongField } from "../../Field/TextLongField";
import { FilesField } from "../../Field/FilesField";
import { FormAlertIntern } from "../../UI/FormAlertIntern";

const useStyles = makeStyles(theme => ({
  detailsBox: {
      paddingRight: "50px",
      borderRight: "solid thin",
      marginRight: "50px",
  },
  raGridElem: {
    marginBottom: '10px',
    marginTop: '10px'
  }
}));

export const AlertShow = (props) => {
  const classes = useStyles();

  const ipr = (a) => ({
    align: a?"left":"center",
    className: classes.raGridElem,
    item: true,
    xs: a?10:2
  })

  let newprops = Object.assign({}, props);
  delete newprops.mini;
  delete newprops.hideAlertEvent;

  return (
      <Show actions={props?.mini?null:<AlertShowActions />} {...newprops} >
        <Tabbed ipr={ipr}/>
      </Show>
  );
};

const Tabbed = (props) => {
  const ipr = props.ipr;
  const record = useRecordContext(props);
  const { user } = useSelector(state => state.account)

  let lastSuiviResolved = false;
  record?.alert_events?.forEach(alert_event => {
    if ( alert_event.status === "RESOLVED" ) {
      lastSuiviResolved = alert_event;
    }
  })

  let newprops = Object.assign({}, props);
  delete newprops.ipr;

  return (
    <TabbedShowLayout {...newprops}>
      <Tab label="Alerte">
        <GridShowLayout >
          <RaGrid container>
            <RaGrid item md={6}>
              <RaGrid container justifyContent="center" alignItems="center">

                <RaGrid {...ipr(0)}><AlertStatusField /></RaGrid>
                <RaGrid {...ipr(1)}><FunctionField label="Statut :" render={record => `${translateState(record.status)}`}/></RaGrid>

                <RaGrid {...ipr(0)}><><PinDropIcon/></></RaGrid>
                <RaGrid {...ipr(1)}><LocalisationField/></RaGrid>

                <RaGrid {...ipr(0)}><><DescriptionIcon/></></RaGrid>
                <RaGrid {...ipr(1)}>
                  <>
                  <TextField label="Constatation :" source="description" />
                  { user?.collectivite?.admin === true &&
                  <DescriptionGeneratorModal record={record} user={user}></DescriptionGeneratorModal>
                  }
                  </>
                </RaGrid>

                <RaGrid {...ipr(0)}><><ScheduleIcon/></></RaGrid>
                <RaGrid {...ipr(1)}><DateField label="Posté le :" source="created_at" showTime  /></RaGrid>

                <RaGrid {...ipr(0)}><><PersonIcon/></></RaGrid>
                <RaGrid {...ipr(1)}>
                  <UserField label="Utilisateur :" source="user"></UserField>
                </RaGrid>

                <RaGrid {...ipr(0)}>
                  <>
                      {(lastSuiviResolved && user?.collectivite?.id === lastSuiviResolved?.user?.collectivite?.id && record?.status === "RESOLVED") && <>
                          <VerifiedUserIcon/>
                      </>}
                  </>
                </RaGrid>
                <RaGrid {...ipr(1)}><>
                        {(lastSuiviResolved && user?.collectivite?.id === lastSuiviResolved?.user?.collectivite?.id && record?.status === "RESOLVED") && <>
                            Vous avez résolu cette alerte.<br /><br />
                            { (record?.report_info && parseFloat(record?.report_info['total']) > 0) && <>
                                <EditButton basePath="/alert-events" label="Editer le bilan" variant="contained" record={lastSuiviResolved} />
                            </>}
                            {!record?.report_info && <>
                                <EditButton basePath="/alert-events" label="Ajouter un bilan" variant="contained" record={lastSuiviResolved} />
                            </>}
                        </>}
                        </>
                </RaGrid>
                
              </RaGrid>
            </RaGrid>
            <RaGrid item md={6}>
              <AlertImagesGalleryField source="images" maxWidth="xs" />
            </RaGrid>
          </RaGrid>
        </GridShowLayout >
      </Tab>
      <Tab label="Carte">
        <MapField></MapField>
      </Tab>
      <TabSuivi />
      <TabComment />
      <TabBilan />
      <TabIntern />
    </TabbedShowLayout>
  )
}

const TabInternAddButton = (props) => {

    const { record } = props;
    const [showForm, setShowForm] = useState(false);
    const refresh = useRefresh();

    const onSubmitSuccess = () => {
      console.log('onSubmitSuccess');
      setShowForm(false);
      refresh();
    }
      return (<>
        <br />
        <br />
        <Button color="primary" variant="contained" onClick={() => setShowForm(!showForm)}>
          {showForm?'Fermer le formulaire':'Ajouter une note interne'}
        </Button>
        <br />
        <br />
        {showForm && 
          <Paper>
            <Box m={1} p={1}>
              <FormAlertIntern 
                onSubmitSuccess={onSubmitSuccess}
                record={{
                id:record.id, 
                recipients:[]
                }}/>
            </Box>
          </Paper>
        }
      </>)

}

export const TabInternList = (props) => {
  const {record} = props;

  if (!record?.id) {
    return ('No Alert To List Internal Notes');
  }
  return (
    <List 
      basePath="/alert-interns" 
      filter={{alert:record.id}} 
      perPage={10} 
      bulkActionButtons={false} actions={false} 
      pagination={<Pagination rowsPerPageOptions={[5, 10]}/>} 
      title=" " 
      empty={<span>Pas de notes interne</span>} 
      {...props} >
      <Datagrid>
        <TextField label="id" source={"id"}></TextField>
        <TextLongField source="description" label=""></TextLongField>
        <FilesField source="documents" mode="nb"></FilesField>
        <UserField label="Créateur" source="user"></UserField>
        <ShowButton label="Voir" />
      </Datagrid>
    </List>
  )
}

export const TabIntern = (props) => {
  let record = useRecordContext(props);
    if (!record) { record = props?.record; }
  
  return (
    <Tab label={"Notes internes "} {...props} disabled={false}>
      <ResourceContextProvider value={"alert-interns"} >
        <TabInternList record={record}/>
      </ResourceContextProvider>
      <TabInternAddButton record={record} source={"alert_interns"}/>
    </Tab>
  )
}

const TabBilan = (props) => {
  const record = useRecordContext(props);
  const { user } = useSelector(state => state.account)

  let lastSuiviResolved = false;
  record?.alert_events?.forEach(alert_event => {
    if ( alert_event.status === "RESOLVED" ) {
      lastSuiviResolved = alert_event;
    }
  })

  return (
    <Tab label="Bilan" {...props} >
      {lastSuiviResolved && <>
        { (user && lastSuiviResolved?.user?.collectivite?.id === user?.collectivite?.id) && <>
          <Grid container>
            <Grid item lg={6}>
              <ResourceContextProvider value="alert-events">
                <AlertEventEditForm id={lastSuiviResolved.id} resource="alert-events" basePath=""/>
              </ResourceContextProvider>
            </Grid>
            <Grid item lg={6}>
              <Box m={2}>
                <Card>
                  <br />
                  <CardHeader title={"Bilan"}></CardHeader>
                  <CardContent>
                    <ShowDetailQuantity item={record} title={"Aperçu du bilan"}></ShowDetailQuantity>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </> }
        { (!user || lastSuiviResolved?.user?.collectivite?.id !== user?.collectivite?.id) && <>
              <ShowDetailQuantity item={record} title={""}></ShowDetailQuantity>
        </>}
      </>}
      {!lastSuiviResolved && <>
        <Typography style={{margin:20}}>
        {"Pas de bilan sans que l'alerte soit résolu"}
        </Typography>
        </>
      }
    </Tab>
  )
}

const TabSuivi = (props) => {
  const record = useRecordContext(props);

  return (
      <Tab label={"Suivis ("+record?.alert_events.length +")"} {...props} disabled={(record?.alert_events.length === 0)?true:false}>
        <>
          <AlertEventListFromAlert />
          {/* <br /> */}
        </>
      </Tab>
  )
}

const TabComment = (props) => {
  const record = useRecordContext(props);

  return (
    <Tab label={"Commentaires ("+record?.alert_comments.length +")"} {...props} disabled={(record?.alert_comments.length === 0)?true:false}>
      <>
        <AlertCommentListFromAlert />
        <br />
      </>
    </Tab>
  )
}

const AlertShowActions = ({ data }) => {
  const { status: userStatus } = useSelector( state => state.account)
  const account = useSelector( state => state.account)
  const offlineContext = useContext(OfflineContext);
  const [partnerSendAlertExist, setPartnerSendAlertExist] = useState(false)

  const handleSubmitPartner = () => {
    
    let payload = {
        partner: "Clikeco",
        alert: data?.id
    };
    // console.log(data)
    fetchToken(api.url + `/partners/sendAlert`, {
        method: "POST",
        body:JSON.stringify(payload)
    }).then(({json}) => {
        // console.log("DONE")
        window.location.reload();
    }).catch((e) => {
      console.log("error:", e);
    })
  }

  useEffect(() => {
    if (!data?.id) return ;
    fetchToken(api.url + `/partners/stateAlert?partner=${"Clikeco"}&alert=${data?.id}`, {
      method: "GET",
    }).then(({json}) => {
        // console.log("stateAlert result:", json);
        setPartnerSendAlertExist(json.exist);
    }).catch((e) => {
      console.log("error:", e);
    })
  }, [data?.id])

  // If Not connected
  if (!account?.user?.id) {
    return (<Box mr={2}>
      <TopToolbar onClick={offlineContext.toggle}>
        <Box mr={2} >
          <AddEventDialog alertId={data?.id} disabled={true} icon={<LockIcon />}></AddEventDialog>
        </Box>
        <AddCommentDialog alertId={data?.id} disabled={true}  icon={<LockIcon />}></AddCommentDialog>
      </TopToolbar>
    </Box>);
  } else {
    // If Connected
    return (
      <Box mr={2}>
        <TopToolbar>
          <Box mr={2}>{!userStatus?.certified ? <>Fonctions désactivés, compte en attente de validation.</>:""}</Box>

          {account?.user?.collectivite?.admin === true && 
          <Box mr={2}>
            <Button 
              variant="outlined" 
              color="primary" 
              
              // component={Link}
              disabled={data?.status === "RESOLVED" || partnerSendAlertExist}
              onClick={()=>{console.log("Envoyer à clikeco");handleSubmitPartner()}}
              >
                { partnerSendAlertExist?"Envoyé à clikeco":"Envoyer à clikeco" }
              </Button>
          </Box>}
          <Box mr={2}>
            <Button 
            variant="outlined" 
            color="primary" 
            component={Link}
            disabled={data?.status === "RESOLVED"}
            to={{
              pathname: '/alertEventCreate/'+data?.id,
              state: { record: { alert: data?.id } },
            }}
            >
              Ajouter un suivi
            </Button>
          </Box>
          <AddCommentDialog alertId={data?.id} disabled={!userStatus?.certified}></AddCommentDialog>
        </TopToolbar>
      </Box>
    );
  }
};