import React, { useState }      from "react";
import {
  Show,
  DateField,
  SimpleShowLayout,
  useRecordContext,
  ResourceContextProvider
} from "react-admin";
import {
  Box,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import { UserField, UsersField } from "../../Field/UserField";
import { TextLongField } from "../../Field/TextLongField";
import { AlertShowCard } from "../Alert/AlertShowCard";
import { FormAlertIntern } from "../../UI/FormAlertIntern";
import { FilesField } from "../../Field/FilesField";
import { TabInternList } from "../Alert/AlertShow";

export const AlertInternShow = (props) => {
  return (<>
      <Show aside={<ASideComponent/>} {...props}>
        <SimpleShowLayout>
          
          <UserField label="Crée par" source="user"></UserField>
          <DateField label="Le " source="created_at" />
          <TextLongField source="description" label="Description" expandable={false} forceOpen={true}/>
          <FilesField source="documents" label="Documents" /><br />
          <UsersField source="recipients" label="Destinataires" /><br />
          
          <AlertInternCreateField source="recipients" />
          {/* <AlertInternShowMoreOnSameAlert /> */}
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ASideComponent = (props) => {
    const record = useRecordContext();
    return (
      <div style={{ width: 400, margin: '1em' }}>
        <AlertShowCard alert={record?.alert} />
      </div>
    );
};

const AlertInternShowMoreOnSameAlert = (props) => {
  const record = useRecordContext();
  const [showMore, setShowMore] = useState(false);

  return (<>

      <Button color="primary" variant="contained" onClick={() => setShowMore(!showMore)}>
        {showMore?'Fermer':'Afficher les autres notes concernant cette alerte'}
      </Button>

      {showMore && <>
      <br /><br />
      <Typography variant="caption" color={'textSecondary'} style={{marginTop:20}}>
        Autres notes interne sur la même alerte:
      </Typography>

        <ResourceContextProvider value={"alert-interns"} >
            <TabInternList record={record?.alert}/>
          </ResourceContextProvider>
        
      </>}
    </>
  )
}

const AlertInternCreateField = (props) => {
  const { source, record } = props;
  const [showForm, setShowForm] = useState(false);

  if (record[source]) {
    return (<>
      <br />
      <Button color="primary" variant="contained" onClick={() => setShowForm(!showForm)}>
        {showForm?'Fermer le formulaire de réponse':'Répondre'}
      </Button>
      <br />
      {showForm && 
        <Paper>
          <Box m={1} p={1}>
            <FormAlertIntern record={{
              id:record?.alert?.id, 
              recipients:[...record?.recipients, record?.user?.id]
            }}/>
          </Box>
        </Paper>
      }
    </>)
  }
  return (<span>Alert bad format</span>)
}