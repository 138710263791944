import { Cookies } from '../helpers/Cookies'
import {api} from './config'
import { fetchJsonWithToken } from './httpClientToken'


export const strapiAuth =  {

    register: (registerData, success, error) => {
        const request = new Request(api.url + '/auth/local/register', {
            method: 'POST',
            body: JSON.stringify(registerData),
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.statusCode < 200 || response.statusCode >= 300) {
                    error(response)
                } else {
                    success(response) 
                }
            }).catch(err=> {
                error(err);
            });
    },

    login: ({ username, password, provider = "local", token = null }) => {
        let request = null;

        localStorage.removeItem("reduxFilter")
        localStorage.removeItem("CollectivityFilter")
        localStorage.removeItem("SESSION_AREA")
        localStorage.removeItem("user")

        switch (provider) {
            case "local":
                //console.log("Login local strapi")
                request = new Request(api.url + '/auth/local', {
                    method: 'POST',
                    body: JSON.stringify({ identifier: username, password }),
                    headers: new Headers({ 'Content-Type': 'application/json'})
                });
            break;

            case "facebook":
                //console.log("Login with facebook")
                request = new Request(api.url + `/auth/facebook/callback?access_token=${token}&force=1`, {
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json'})
                });
            break;
            case "apple":
                //console.log("Login with facebook")
                request = new Request(api.url + `/auth/apple/callback?access_token=${token}&force=1&origin=admin-login`, {
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json'})
                });
            break;
            default:
                console.log("Account provider not supported yet.")
        }

        return fetch(request)
            .then(response => response.json())
            .then(response => {
                // console.log(response);
                if (!response?.jwt || response.statusCode < 200 || response.statusCode >= 300) {
                    if (api.parseError(response)) {
                        switch (api.parseError(response)) {
                            case "Auth.form.error.confirmed":
                                // console.log("Facebook login as already email registered")
                                throw new Error("Auth.form.error.confirmed");

                            case "Auth.form.error.invalid":
                                console.log(api.parseError(response, 1))
                                throw new Error("account.credential.invalid");

                            default:
                                throw new Error(api.parseError(response));
                                // console.log(api.parseError(response));
                        }
                    } else {
                        console.log("Error unknow : ", response)
                    }
                } else {
                        Cookies.setCookie('token', response.jwt, 1);
                        Cookies.setCookie('role', response.user.role.name, 1);
                        if (token) { 
                            Cookies.setCookie(provider+"_token", token, 1); 
                            localStorage.setItem(provider+"_token", token);
                        }

                        // console.log("setItem user:", response.user)
                        localStorage.setItem("user", JSON.stringify(response.user))
                        // strapiAuth.getIdentity();
                }
            });
    },

    logout: () => {
        Cookies.deleteCookie('token');
        Cookies.deleteCookie('role');
        localStorage.removeItem("reduxFilter");
        localStorage.removeItem("CollectivityFilter");
        localStorage.removeItem("SESSION_AREA");
        localStorage.removeItem("user");

       

        return Promise.resolve();
    },

    checkAuth: () => {
        return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            Cookies.deleteCookie('token');
            Cookies.deleteCookie('role');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = Cookies.getCookie('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },

    getIdentity: () => {
        return loadUser(
            Cookies.getCookie('token'), 
            localStorage.getItem("user")
        )
    },

    refreshIdentity: () => {
        return loadUser(
            Cookies.getCookie('token'), 
            null
        )
    },

    updateUserProfile: (id,params) => {
        return new Promise((resolve, reject) => {
            fetchJsonWithToken(api.url+`/users/${id}`, {
                method: "PUT",
                body:JSON.stringify(params)
            }).then(({json}) => {
                let user = {};
                loadUserWithCollectivity(user, resolve, reject)
            }).catch((e) => {
                reject(e);
            })
        })
    }
}

const loadUser = (token, userStored) => {
    return new Promise ((resolve, reject) => {


        if (token && userStored) {
            // console.log("Parse localStorage User data")
            let user = JSON.parse(userStored);
            
            if (
                    user?.name && 
                    (!user?.collectivite?.id || user?.collectivite?.page?.id) 
                ) {
                resolve(user);
            } else {
                loadUserWithCollectivity(user, resolve, reject)
            }
        } else if (token) {
            let user = {};
            loadUserWithCollectivity(user, resolve, reject)
        } else {
            reject(null);
        }
    })
}

const loadUserWithCollectivity = (user, resolve, reject) => {
    fetchJsonWithToken(api.url + '/users/meFull').then(({json}) => {
        user = json;
        if (json?.collectivite?.id) {
            fetchJsonWithToken(api.url + '/collectivities/'+json?.collectivite?.id).then(({json:collectivite}) => {
                user.collectivite = collectivite;
                localStorage.setItem("user", JSON.stringify(user))
                resolve(user);
            }).catch(error => {
                console.log("collectivite not found");
                reject(null)
            })
        } else {
            localStorage.setItem("user", JSON.stringify(user))
            resolve(user);
        }
    }).catch(error => {
        console.log("user not found");reject(null)
    })
}