import React, { useCallback } from 'react';
import { strapiImage } from '../../../api'
import { Card, CardContent, CardActionArea, CardMedia, CardHeader, Chip, Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 345,
    },
    titleHeader: {
        fontSize:"0.7em",
        margin: 0,
        padding: 0,
        fontWeight:"bold",
        minHeight: 45
    },
    header: {
        backgroundColor:theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: "1em",
        minHeight: "20px"
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        backgroundColor:theme.palette.secondary.main
    },
    checkIcon: {
        color: "#00FF00", 
        position:"absolute", 
        backgroundColor:"#FFFFFF99", 
        right:"0px"
    },
    cardContent: {
        maxHeight:"200px", 
        marginBottom: 8
    }
}));

export const TrainingCard = ({training, status = true}) => {

    const classes = useStyles();
    const history = useHistory();
    const color= useTheme();
    const translate = useTranslate();
    // eslint-disable-next-line
    const handleOnClick = useCallback(() => history.push('/training/'+training?.id), [history]);

    return (
        <Card className={classes.root} onClick={handleOnClick} >
            <CardActionArea component="span">
                <CardHeader title={<p className={classes.titleHeader}>{training?.Title}</p>} className={classes.header} ></CardHeader>
                <CardMedia
                    className={classes.media}
                    image={strapiImage(training?.Images[0], '+').url}
                    title={training?.Title}
                >

                </CardMedia>
                { status && 
                    <CardContent>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            >
                            <Grid item>
                                <Chip
                                    label={translate('training.state.'+training?.state)}
                                    clickable
                                    color="secondary"
                                    style={{backgroundColor:training?.state==="AVAILABLE"?color.palette.secondary.main:color.palette.warning.main}}
                                />
                            </Grid>
                            <Grid item>
                                { training?.state==="AVAILABLE" && 
                                    <Button variant="contained" color="primary" size='small'>Accéder au séminaire</Button>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                }
            </CardActionArea>
        </Card>
    )
}