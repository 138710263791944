import  React, { useEffect } from "react";
import { Title,  useCreateController, Create, SimpleForm, RadioButtonGroupInput, required, useTranslate, TextInput, EditButton, ShowButton } from 'react-admin';
import { useParams } from "react-router-dom";
import { Grid,FormControlLabel,FormControl,FormLabel,makeStyles,IconButton, RadioGroup, Radio, Box, TextField, Button, Card, CardContent, CardHeader, CardActions  } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { AlertShow } from "../../Resources/Alert/AlertShow";
import { ResourceContextProvider } from "react-admin";
import { FormDetailQuantity } from "../../UI/DetailQuantity";
import api, {fetchToken} from '../../../api'
import { FormAlertIntern } from "../../UI/FormAlertIntern";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export function UploadButtons() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                </label>
            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label>
        </div>
    );
}

export const AlertEventCreate = (props) => {
    const { alertid } = useParams();
    const classes = useStyles();
    const [status, setStatus] = React.useState(false);
    const [commentaire, setCommentaire] = React.useState("");
    const [images, setImages] = React.useState([])
    const [detailQuantity, setDetailQuantity] = React.useState({});

    const updateDetailQuantity = (e) => {
        // console.log('updateDetailQuantity', e);
        setDetailQuantity(e);
    }

    useEffect(() => {
        // console.log('detailQuantity:', detailQuantity);
    }, [detailQuantity]);

    const handleSubmit = () => {
        let data = {
            status: status,
            description: commentaire,
            alert: alertid,
            info: {quantity: detailQuantity}
        };

        let toUpload = []
        images.forEach(image => {
            toUpload.push(image.file)
        })

        fetchToken(api.url + `/alert-events`, {
            method: "POST",
            body:JSON.stringify(data),
        },toUpload).then(({json}) => {
            window.location.replace('/#/alerts/'+alertid+'/show')
        })
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleInputImages = (event) => {
        let uploadedFiles = [];
        var files = event.target.files;
        for (var i = 0; i < files.length; i++) {
            uploadedFiles.push({
                base64: URL.createObjectURL(files[i]),
                file: files[i]
            })
        }
        setImages(uploadedFiles);
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <ShowButton basePath="/alerts" label="Retour" record={{id:alertid}}></ShowButton>
                </Grid>
                <Grid item md={6}>
                    <ResourceContextProvider value="alerts">
                        <AlertShow id={alertid} resource="alerts" mini={true} basePath="/" />
                    </ResourceContextProvider>
                </Grid>
                <Grid item md={6}>
                    <Title title=" Ajout de suivi" />
                    <Card style={{marginTop:"16px"}}>
                        <CardHeader title="Ajouter un suivi"></CardHeader>
                        <CardContent>
                            <FormControl component="fieldset">
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup aria-label="Typert" name="status" value={status} onChange={handleChange}>
                                Interne :
                                <FormControlLabel value="PROCESSING_INTERNAL" control={<Radio />} label="Note interne" />
                                <br />
                                Public :
                                <FormControlLabel value="PROCESSING" control={<Radio />} label="Procédure en cours" />
                                <FormControlLabel value="RESOLVED" control={<Radio />} label="Résolu" />
                            </RadioGroup>
                            </FormControl><br />
                            { (status === "RESOLVED" || status === "PROCESSING") && <>
                                <br />
                                <hr />
                                <br />
                                { status === "RESOLVED" && <FormDetailQuantity setDetailQuantity={updateDetailQuantity} detailQuantity={detailQuantity}></FormDetailQuantity>}
                                <TextField
                                    style={{marginTop:"20px"}}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    value={commentaire}
                                    label="Commentaires"
                                    onChange={(e) => setCommentaire(e.target.value)}
                                    type="email"
                                    fullWidth
                                    multiline
                                />
                                <Box mb={6}></Box>
                                Images :
                                {images.map((image, index) => {
                                    return <img width="100" src={image.base64} alt={"upload"+index} key={"upload"+index}/>
                                })}
                                <input accept="image/*" id="icon-button-file" type="file" multiple className={classes.input} onChange={handleInputImages}/>
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                                <br />
                                <CardActions ><Button color="primary" variant="contained" style={{marginLeft: "auto"}}  onClick={handleSubmit}>Ajouter</Button></CardActions>
                            </>}

                            { (status === "PROCESSING_INTERNAL") && <>                        
                                <br />
                                <hr />
                                <br />
                                <FormAlertIntern record={{id:alertid}}/>
                            </> }
                        </CardContent>
                        
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};


export const AlertEventCreate2 = (props) => {
    const translate = useTranslate();
    const createControllerProps = useCreateController(props);
    const {
        record, // empty object, unless some values were passed in the location state to prefill the form
    } = createControllerProps;

    if (record?.alert) {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <EditButton basePath="/alerts" label="Back" record={record.alert}></EditButton>
                    <ResourceContextProvider value="alerts">
                        <AlertShow id={record.alert} resource="alerts" mini={true} basePath="/" hideAlertEvent={true}/>
                    </ResourceContextProvider>
                </Grid>
                <Grid item md={6}>
                    <Card style={{marginTop:"16px"}}>
                        <CardHeader title="Ajouter un suivi"></CardHeader>
                        {/* <CardContent> */}
                            <Create title=" - Ajouter un suivi" {...props}>
                                <SimpleForm validate={(toValidate)=>{console.log(toValidate)}}>
                                    <RadioButtonGroupInput source="status" label="Type :" validate={[required()]} row={false} choices={[
                                        { id: 'PROCESSING_INTERNE', name: 'PROcédure INTERNE'},
                                        { id: 'PROCESSING', name: translate('event.status.PROCESSING') },
                                        { id: 'RESOLVED', name: translate('event.status.RESOLVED') }
                                    ]} />
                                    <TextInput source="description" label="Description" multiline fullWidth minRows={10}/>
                                </SimpleForm>
                            </Create>
                        {/* </CardContent> */}
                        {/* <CardActions ><Button color="primary" variant="contained" style={{marginLeft: "auto"}}  onClick={null}>Ajouter</Button></CardActions> */}
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
    } else {
        return (<>Ajoutez un suivis depuis une Alerte</>)
    }
}