export const Cookies = {
	getCookie: (name) => {
		const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    		return v ? v[2] : null;
	},

	setCookie: (name, value, days = 1) => {
		var d = new Date();
    		d.setTime(d.getTime() + (1000*60*60*24*days)); // 24*60*60*1000*days
			console.log('cookie expire:', d.toGMTString());
    		document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
	},

	deleteCookie: (name) => {
		Cookies.setCookie(name, '', -1)
	}
};