import { linkToRecord , useGetOne, useRecordContext, TextField } from 'react-admin';
import { Fragment, useContext } from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OfflineContext } from            "../Layout/AdminOfflineLayout";
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

export const UserField = (props) => {

    const record = useRecordContext(props);

    const { link, label=false } = props;

    if (record?.user?.id) {

        if (link) {
            return(<span>
                <Link to={linkToRecord('/users', record.user.id, 'show')}>{record.user.username}</Link>
            </span>);
        } else {
            return(<>
                    { label && <><Typography variant="caption" color={'textSecondary'} >{label} :</Typography><br /></> }
                    <TextField label="Créateur" record={record} source={"user.username"}></TextField>
                </>
            );
        }
    }
    return (<span>User bad format</span>)
}

export const LocalisationField = (props) => {
    const offlineContext = useContext(OfflineContext);
    const record = useRecordContext(props);
    return (<>
        {record.county} {record.epci} <br />
        {record.postcode} &nbsp;{record.town} 
        {offlineContext?.mode !== "offline" && 
            <><br />GPS: {record.latitude.toFixed(6)} {record.longitude.toFixed(6)}</>
        }
    </>)
}

export const UserField2 = ({ source, record = {} }) => {

    const { data } = useGetOne('users', record.user);
    if (data) {
        if (data.id && data.username)
            return (<Link to={linkToRecord('/users', data.id, 'show')}>{data.username}</Link>)
    }
    return (<span>User bad format</span>)
}

export const UsersField = (props) => {

    const { source, record, label } = props;
    const classes = useStyles();
    if (record[source] && record[source]?.length > 0) {
            return (
            <>
                <Typography variant="caption" color={'textSecondary'} style={{marginTop:20}}>{label} :</Typography>
                <TableContainer component={Paper} style={{        marginTop: 10}}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell >Prénom</TableCell>
                            <TableCell >Pseudonyme</TableCell>
                            <TableCell align="right">Collectivité</TableCell>

                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {record[source].map((user, key) => (
                            <TableRow key={'uy'+key}>
                            <TableCell component="th" scope="row">
                                {user.name}
                            </TableCell>
                            <TableCell >{user?.firstname}</TableCell>
                            <TableCell >{user?.username}</TableCell>
                            <TableCell align="right">{user?.collectivite?.title}</TableCell>

                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            )
    } else {
        return (<span> </span>)
    }
}


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });



UserField.propTypes = {
label: PropTypes.string,
record: PropTypes.object,
source: PropTypes.string.isRequired,
};