import React, { useContext } from 'react'
import { Sidebar } from 'react-admin';
import { MenuItemLink, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Divider, Icon } from '@material-ui/core';
import { createElement } from 'react';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ShareIcon from '@material-ui/icons/Share';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SchoolIcon from '@material-ui/icons/School';
import LockIcon from '@material-ui/icons/Lock';
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import{ strapiImage } from '../../api';
import {Status} from '../../helpers/Status';
import { OfflineContext } from "./AdminOfflineLayout";
import { DialogLAPADEContext } from "./AdminLayout";

const useIconStyles = makeStyles(theme => ({
    color: {
        color: theme.palette.secondary.main,
    },
    lock: {
        color: "#FF0000"
    }
}))

const SidebarHeader = ({user, open}) => {
    const theme = useTheme();

    if (user && user?.id) {
        return (
            <div className="header-sidebar" style={{maxWidth: theme.sidebar.width}}>
                {(open && user?.collectivite) &&  <>
                    <div style={{ padding:16}}>
                        {user.collectivite?.parent && user.collectivite.parent.length > 0 && user.collectivite.parent[0]?.logo && <>
                            <center><img alt="collectivity_icon" src={strapiImage(user.collectivite.parent[0].logo,'<').url} width="200" /></center>
                            <Divider style={{margin:'20px 0px'}}></Divider>
                        </>}
                        {user.collectivite?.logo && <>
                            <center><img alt="collectivity_icon" src={strapiImage(user.collectivite.logo,'<').url} width="200" /><br /></center>
                        </>}
                        <br />
                        {user?.collectivite?.type || "Collectivité"} : <br /><b>{user.collectivite.title}</b><br /><br />
                    </div>
                    <Divider />
                </>}
            </div>
        )
    } else {
        return (<><br /><br /><br />TTTTTTT<br /><br /><br /></>);
    }
}

export const AdminSidebar = props => {
    const {onMenuClick, logout} = props;
    const { user }              = useSelector(state => state.account)
    const isXSmall              = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open                  = useSelector(state => state.admin.ui.sidebarOpen);
    const resources             = useSelector(getResources);
    const icon                  = useIconStyles();
    const status                = Status(user);
    const offlineContext        = useContext(OfflineContext);
    const dialogLAPADEContext   = useContext(DialogLAPADEContext);

    const onMenuClickAlertDisabled = (event) => {
        event.preventDefault();
        dialogLAPADEContext.toggleAlert();
    }

    const onMenuClickTrainingDisabled = (event) => {
        event.preventDefault();
        dialogLAPADEContext.toggleBiblio();
    }

    if (user?.username) {
        return (
            <Sidebar open={open}>
                <SidebarHeader user={user} open={open} />
                {!status.type !== "Public" &&
                    <MenuItemLink
                        to="/dashboard"
                        key="Dashboard"
                        primaryText={open?"Tableau de Bord":""}
                        leftIcon={<DashboardIcon className={icon.color}/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                }
                <br />

                {user?.role?.name === "Collectivité" && <>
                    <MenuHeader>Alertes</MenuHeader>
                    {!status.type !== "Public" &&

                    <MenuItemLink
                        to="/carte"
                        key="map"
                        primaryText={open?"Carte":""}
                        leftIcon={!status?.alert?<LockIcon className={icon.color}/>:<RoomIcon className={icon.color}/>}
                        onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />}

                    {resources.map((resource, index) => {
                        if (resource?.options?.label) {
                            return (
                            <MenuItemLink
                                key={resource.name+index}
                                to={`/${resource.name}`}
                                primaryText={open?resource.options && (resource.options.label || resource.name):""}
                                leftIcon={!status?.alert?<LockIcon className={icon.color}/>:createElement((resource.icon || RoomIcon), {className:icon.color})}
                                onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                                sidebarIsOpen={open}
                            />)
                        } else return (null)
                    })}

                    {status.type === "Collectivité" &&
                    <>
                        <MenuItemLink
                            to="/documents#Aide%20documentaire"
                            key="Aide-documentaire"
                            primaryText={open?"Aide Documentaire":""}
                            leftIcon={!status?.alert?<LockIcon className={icon.color}/>:<LocalHospitalIcon className={icon.color}/>}
                            onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                            sidebarIsOpen={open}
                        />
                        {/* <MenuItemLink
                            to="/aide-documentaire"
                            key="Aide-documentairev2"
                            primaryText={open?"Aide Documentaire":""}
                            leftIcon={!status?.alert?<LockIcon className={icon.color}/>:<LocalHospitalIcon className={icon.color}/>}
                            onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                            sidebarIsOpen={open}
                        /> */}
                    

                    {/* La pade Bibliothèque */}
                    <MenuHeader>Bibliothèque</MenuHeader>
                    <MenuItemLink
                        to="/training"
                        key="Seminaires"
                        primaryText={open?"Séminaires":""}
                        leftIcon={!status?.training?<LockIcon className={icon.color}/>:<SchoolIcon className={icon.color}/>}
                        onClick={!status?.training?onMenuClickTrainingDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />

                    <MenuItemLink
                        to="/trainingDocuments"
                        key="Documents"
                        primaryText={open?"Modèles de documents":""}
                        leftIcon={!status?.training?<LockIcon className={icon.color}/>:<AssignmentIcon className={icon.color}/>}
                        onClick={!status?.training?onMenuClickTrainingDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        to="/trainingFaqs"
                        key="FAQs"
                        primaryText={open?"FAQ":""}
                        leftIcon={!status?.training?<LockIcon className={icon.color}/>:<LiveHelpIcon className={icon.color}/>}
                        onClick={!status?.training?onMenuClickTrainingDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />
                    </>}
                </>}

                {status.type === "Collectivité" &&
                    <>
                    <MenuHeader>Partenaire</MenuHeader>
                    <MenuItemLink
                        to="/services"
                        key="Services"
                        primaryText={open?"Enlèvement déchets":""}
                        leftIcon={<><Icon className={icon.color}>support_agent</Icon></>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    </>
                }
                <MenuHeader>Général</MenuHeader>
                {/* <MenuItemLink
                    to="/documents#Kit%20de%20communication"
                    key="Kit-de-communication"
                    primaryText={open?"Kit de Communication":""}
                    leftIcon={<ShareIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                /> */}
                { status.training && 
                    <MenuItemLink
                        to="/kit-de-communication"
                        key="Kit-de-communicationv2"
                        primaryText={open?"Kit de Communication":""}
                        leftIcon={<ShareIcon className={icon.color}/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                }
                {status.type === "Association" && 
                    <MenuItemLink
                        to="/space"
                        key="Space"
                        primaryText={open?"Espace":""}
                        leftIcon={<MenuBookIcon className={icon.color}/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                }

                <MenuItemLink
                    to="/account"
                    key="Account"
                    primaryText={open?"Mon Compte":""}
                    leftIcon={<AccountCircleIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                <MenuItemLink
                    to="/contact"
                    key="Contact"
                    primaryText={open?"Contact":""}
                    leftIcon={<ContactMailIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

   

                <br /><br /><br />
                {isXSmall && logout}
            </Sidebar>
        );
    } else {
        return (
            <Sidebar open={open} onClick={offlineContext.toggle}>
                {/* <SidebarHeader user={user} open={open} />
                <MenuItemLink
                    to="/dashboard"
                    key="Dashboard"
                    disabled
                    primaryText={open?"Tableau de Bord":""}
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                {resources.map((resource, index) => {
                    if (resource?.options?.label) {
                        return (
                        <MenuItemLink
                            key={resource.name+index}
                            to={`/${resource.name}`}
                            disabled
                            primaryText={open?resource.options && (resource.options.label || resource.name):""}
                            leftIcon={<LockIcon className={icon.color}/>} 
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />)
                    } else return (null)
                })}

                <MenuItemLink
                    to="/documents#Kit%20de%20communication"
                    key="Kit-de-communication"
                    primaryText={open?"Kit de Communication":""}
                    disabled
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                <MenuItemLink
                    to="/documents#Aide%20documentaire"
                    key="Aide-documentaire"
                    disabled
                    primaryText={open?"Aide Documentaire":""}
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                <MenuItemLink
                    to="/training/1"
                    key="Seminaires"
                    disabled
                    primaryText={open?"Formation":""}
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                {status.type === "Association" && 
                <MenuItemLink
                    to="/space"
                    key="Space"
                    primaryText={open?"Espace":""}
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />}

                <MenuItemLink
                    to="/account"
                    key="Account"
                    disabled
                    primaryText={open?"Mon Compte":""}
                    leftIcon={<LockIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <MenuItemLink
                    to="/contact"
                    key="Contact"
                    primaryText={open?"Contact":""}
                    leftIcon={<ContactMailIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                {isXSmall && logout} */}
                <br />
                {!status.type !== "Public" &&
                    <MenuItemLink
                        to="/dashboard"
                        key="Dashboard"
                        disabled
                        primaryText={open?"Tableau de Bord":""}
                        leftIcon={<DashboardIcon className={icon.color}/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                }
                <br />

                {/* {user?.role?.name === "Collectivité" && <> */}
                    <MenuHeader>Alertes</MenuHeader>
                    {!status.type !== "Public" &&

                    <MenuItemLink
                        to="/carte"
                        key="map"
                        disabled
                        primaryText={open?"Carte":""}
                        leftIcon={!status?.alert?<LockIcon className={icon.color}/>:<RoomIcon className={icon.color}/>}
                        onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />}

                    {resources.map((resource, index) => {
                        if (resource?.options?.label) {
                            return (
                            <MenuItemLink
                                key={resource.name+index}
                                to={`/${resource.name}`}
                                disabled
                                primaryText={open?resource.options && (resource.options.label || resource.name):""}
                                leftIcon={!status?.alert?<LockIcon className={icon.color}/>:createElement(resource.icon, {className:icon.color})}
                                onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                                sidebarIsOpen={open}
                            />)
                        } else return (null)
                    })}

                    {/* {status.type === "Collectivité" &&
                    <> */}
                        <MenuItemLink
                            to="/documents#Aide%20documentaire"
                            key="Aide-documentaire"
                            disabled
                            primaryText={open?"Aide Documentaire":""}
                            leftIcon={!status?.alert?<LockIcon className={icon.color}/>:<LocalHospitalIcon className={icon.color}/>}
                            onClick={!status?.alert?onMenuClickAlertDisabled:onMenuClick}
                            sidebarIsOpen={open}
                        />
                    

                    {/* La pade Bibliothèque */}
                    <MenuHeader>Bibliothèque</MenuHeader>
                    
                    <MenuItemLink
                        to="/training"
                        key="Seminaires"
                        disabled
                        primaryText={open?"Séminaires":""}
                        leftIcon={!status?.training?<LockIcon className={icon.color}/>:<SchoolIcon className={icon.color}/>}
                        onClick={!status?.training?onMenuClickTrainingDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />

                    <MenuItemLink
                        to="/trainingDocuments"
                        key="Documents"
                        disabled
                        primaryText={open?"Modèles de documents":""}
                        leftIcon={!status?.training?<LockIcon className={icon.color}/>:<AssignmentIcon className={icon.color}/>}
                        onClick={!status?.training?onMenuClickTrainingDisabled:onMenuClick}
                        sidebarIsOpen={open}
                    />
                    {/* </>} */}
                {/* </>} */}

                <MenuHeader>Général</MenuHeader>
                <MenuItemLink
                    to="/documents#Kit%20de%20communication"
                    key="Kit-de-communication"
                    disabled
                    primaryText={open?"Kit de Communication":""}
                    leftIcon={<ShareIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                {/* {status.type === "Association" &&  */}
                    <MenuItemLink
                        to="/space"
                        key="Space"
                        disabled
                        primaryText={open?"Espace":""}
                        leftIcon={<MenuBookIcon className={icon.color}/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                {/* } */}

                <MenuItemLink
                    to="/account"
                    key="Account"
                    disabled
                    primaryText={open?"Mon Compte":""}
                    leftIcon={<AccountCircleIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />

                <MenuItemLink
                    to="/contact"
                    key="Contact"
                    disabled
                    primaryText={open?"Contact":""}
                    leftIcon={<ContactMailIcon className={icon.color}/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                <br /><br /><br />
                {isXSmall && logout}
            </Sidebar>
        )
    }
};

const MenuHeader = ({children}) => {
    return (<div className='MuiMenuItem-root MuiListItem-gutters'>
            <b>{children}</b>
        </div>
    )
}