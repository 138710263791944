import  React, {useEffect} from "react";
import { Grid,FormControlLabel,FormControl,FormLabel,TextField } from '@material-ui/core';
import { FormGroup, Switch, FormHelperText } from '@material-ui/core';
import { useTranslate, useInput } from 'react-admin';
import { MyGallery } from "./Gallery";

export function FormDetailQuantity({setDetailQuantity, detailQuantity=false, label="Détail", helper=""}) {
    const translate = useTranslate();

    // console.log(detailQuantity);
    let quantity = detailQuantity?detailQuantity:{
        'plastic': 0,
        'metal': 0,
        'glass': 0,
        'butts': 0,
        'all': 0,
        'other': 0,
        total: 0
    };

    // console.log('quantity', quantity);
    const setQuantity = setDetailQuantity;

    const [state, setState] = React.useState({
        'plastic': quantity['plastic']>0?true:false,
        'metal':   quantity['metal']>0  ?true:false,
        'glass':   quantity['glass']>0  ?true:false,
        'butts':   quantity['butts']>0  ?true:false,
        'all':     quantity['all']>0    ?true:false,
        'other':   quantity['other']>0  ?true:false,
    });

    const handleChange = (event) => {
        const {name, checked} = event.target;
        if (!checked) {
            setQuantity({...quantity, [name]: 0});
        }
        setState({ ...state, [name]: checked });
    };

    const handleChangeQuantity = (event) => {
        const {name, value} = event.target;
        let total = 0;
        if (name === "total") {
            total = value;
            setQuantity({ ...quantity, total });
        } else  {
            const newQuantity = { ...quantity, [name]: value}
            setQuantity({ ...newQuantity });
        }
    };

    useEffect(() => {
        if (stateAll()) {
            return ;
        }
        let total = 0;
        for (const [key, value] of Object.entries(quantity)) {
            if (key !== "total"&& !isNaN(parseFloat(value))) {
                total = total + parseFloat(value);
            }
        }
        const t = Math.round(total * 1000) / 1000;
        if (quantity.total !== t && !isNaN(total)) {
            setQuantity({ ...quantity, total:t });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity]);

    const disabledInputTotal = () => {
        let disabled = false;
        Object.keys(state).map((key => {
            if (state[key] === true) {
                disabled = true;
            }
            return null;
        }));
        return disabled;
    }

    const stateAll = () => {
        let enableTotal = true;
        Object.keys(state).map((key => {
            if (state[key] === true) {
                enableTotal = false;
            }
            return null;
        }));
        return enableTotal;
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <FormHelperText>{helper}</FormHelperText>
            <FormGroup>
                {Object.keys(state).map((key => 
                    <Grid container key={key+"hj"}>
                        <Grid item xs={12}><br /></Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Switch checked={state[key]} onChange={handleChange} name={key} />}
                                label={translate('bilan.quantity.'+key)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="outlined-basic" disabled={!state[key]} value={quantity[key]} name={key} label="     Kg" variant="outlined" size="small" type="number" onChange={handleChangeQuantity}/>
                        </Grid>
                    </Grid>
                ))}
                
                <Grid container>
                    <Grid item xs={12}><br /></Grid>
                    <Grid item xs={6}>
                        <center>Total (Kg)</center>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="outlined-basic" name={"total"} disabled={disabledInputTotal()} label="" value={quantity['total']} variant="outlined" size="small" onChange={handleChangeQuantity}/>
                    </Grid>
                </Grid>
            </FormGroup>
        </FormControl>
    )
}

export const ShowDetailQuantity = ({item = false, title="Bilan de la collecte", card=false}) => {

    
    const translate = useTranslate();
    if (item) {
        return (<>
                <Grid container spacing={6}>
                    <Grid item md={card?12:6}>
                        <div dangerouslySetInnerHTML={ { __html: item?.report_description } }></div><br />
                        { item?.report_info && item?.report_info['total'] && parseInt(item?.report_info['total']) > 0 && <>
                        <b>Total ramassé : {item?.report_info['total']+"Kg"}</b>
                        { 
                            Object.keys(item?.report_info).map(
                                (key => {
                                    if (key !== 'total' && item?.report_info[key] && parseInt(item?.report_info[key]) > 0)
                                        return (<p key={"p"+key}> # {translate('bilan.quantity.'+key)} : {item?.report_info[key]} Kg</p>)
                                    return (null)
                                })
                            ) 
                        }
                        </>}
                    </Grid>
                    <Grid item md={card?12:6}>
                        <MyGallery strapiInputs={item?.report_images}></MyGallery>
                    </Grid>
                </Grid>
                {/* <Divider /> */}
        </>)
    }

    return (null);
}

export const DetailQuantityField = (props) => {
    const { label, helper } = props;
    const {
        input: { value, onChange}
    } = useInput(props);

    const goOnChange = (val) => {
        onChange(val);
    }

    return <FormDetailQuantity setDetailQuantity={goOnChange} detailQuantity={value} label={label} helper={helper}/>;
}