import React from "react";

import {
    Datagrid,
    List,
    TextField,
    DateField,
    Filter,
    TextInput,
    ShowButton
} from "react-admin";

// import { useSelector } from 'react-redux'; 
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { ContentHeaderTuto } from "../../UI/TutoVideo";
import { AlertField } from "../../Field/AlertField";
import { UserField } from "../../Field/UserField";
import { TextLongField } from "../../Field/TextLongField";
import { FilesField } from "../../Field/FilesField";

const AlertInternFilter = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="Rechercher" source="q" alwaysOn />
      </Filter>
    );
};
  
export const AlertInternList = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader title={<ContentHeaderTuto title="Notes Interne" type="0" />} />
                    <CardContent>
                        <List
                            filters={<AlertInternFilter />}
                            sort={{ field: 'created_at', order: 'DESC'}}
                            actions={null}
                            bulkActionButtons={false}
                            {...props}
                        >
                            <Datagrid >
                                <TextField source="id" />
                                <UserField label="Créateur" source="user"></UserField>
                                <TextLongField source="description" expandable={true} label="Description"/>
                                <AlertField source="alert" showButton={false} />
                                <FilesField source="documents" mode="nb"/>
                                <DateField source="published_at" label={"Crée le "}/>
                                <ShowButton label="Voir" />
                            </Datagrid>
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};