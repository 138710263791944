import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

import { Accordion, AccordionSummary, AccordionDetails, Typography, FormLabel, FormControl, FormGroup, FormControlLabel, Button, CardActions, IconButton, TextField, Checkbox, makeStyles, CircularProgress  } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { PhotoCamera } from '@material-ui/icons';

import api, { fetchToken } from '../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  start: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',

  },
  end: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  input: {
    display: 'none',
  }
}));

export const FormAlertIntern = ({record, onSubmitSuccess = ()=>{}}) => {

  const {id, recipients = []} = record;
  const user = useSelector(state => state.account.user);
  const classes = useStyles();
  const [sendState, setSendState] = React.useState('idle');

  const defaultAlertEventIntern = {
    user: user?.id,
    alert: id,
    recipients: recipients,
    title: "",
    description: "",
    action: "",
    embedFiles: [],
  }
  const [alertEventIntern, setAlertEventIntern] = React.useState(defaultAlertEventIntern);

  const handleInputFile = (event) => {
    let uploadedFiles = [];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
        uploadedFiles.push(files[i])
    }
    setAlertEventIntern({...alertEventIntern, embedFiles: [...alertEventIntern.embedFiles, ...uploadedFiles]});
  }

  const setSelectedUser = (users) => {
    setAlertEventIntern({...alertEventIntern, recipients: users.map(user => parseInt(user))});
  }

  const handleSubmitToApi = () => {
    setSendState('sending');
    let toUpload = []
    alertEventIntern.embedFiles.forEach(file => {
      toUpload.push(file)
    })
    fetchToken(api.url + `/alert-interns`, {
      method: "POST",
      body:JSON.stringify({...alertEventIntern, embedFiles: null}),
    },toUpload, 'files.documents').then(({json}) => {
      setSendState('send');
      onSubmitSuccess();
    }).catch((e) => {
      console.log("error:", e);
      setSendState('error');
    })
  }

  if (sendState === 'sending') {
    return <CircularProgress />
  }

  if (sendState === 'send') {
    return <>
    Note Ajouté
    <Button onClick={() => {
      setSendState('idle');
      setAlertEventIntern(defaultAlertEventIntern);
    }}>Reset</Button>
    </>
  }

  return (<>
    Destinataires: ({alertEventIntern.recipients.length})<br /><br />
    <UsersCheckboxSelectorForm setSelectedUser={setSelectedUser} recipients={alertEventIntern.recipients}/>
    <br />
    Photos et Documents :
    <input accept="*" id="icon-button-file" type="file" multiple className={classes.input} onChange={handleInputFile}/>
    <label htmlFor="icon-button-file">
      <IconButton color="primary" aria-label="upload picture" component="span">
        <PhotoCamera /><NoteAddIcon />
      </IconButton>
    </label>
    <br />
    {alertEventIntern.embedFiles.map((file, index) => {
        return (<div key={index}> - {file.name} - {file.type}<br /></div>)
    })}
    <TextField
      style={{marginTop:"0px"}}
      autoFocus
      margin="dense"
      id="description"
      value={alertEventIntern.description}
      label="Message"
      onChange={(e) => setAlertEventIntern({...alertEventIntern, description: e.target.value})}
      type="text"
      fullWidth
      multiline 
    />
    <br />
    <CardActions ><Button color="primary" variant="contained" style={{marginLeft: "auto"}}  onClick={handleSubmitToApi}>Ajouter</Button></CardActions>
  </>)
}

export const UsersCheckboxSelectorForm = ({setSelectedUser=() => {}, recipients=[]}) => {
  const [collectivitiesUsersData, setCollectivitiesUsersData] = React.useState([]);
  const user = useSelector(state => state.account.user);
  
  const [selectedRecipients, setSelectedRecipients] = React.useState(recipients.map(item => parseInt(item?.id || item)));

  const addSelectedRecipients = (recipient) => {
    setSelectedRecipients([...selectedRecipients, recipient]);
  };

  const removeSelectedRecipients = (recipient) => {
    setSelectedRecipients(selectedRecipients.filter(r => r !== recipient));
  };

  const selectAllIds = (ids) => {
    setSelectedRecipients([...new Set(selectedRecipients.concat(ids))]);
  }

  const removeAllIds = (ids) => {
    setSelectedRecipients(selectedRecipients.filter(r => !ids.includes(r)));
  }

  const getRecipient = (id=0) => {
    if (id > 0) {
      return selectedRecipients.find(r => parseInt(r) === parseInt(id));
    } else {
      return selectedRecipients;
    }
  }

  const toggleSelectedRecipients = (recipient) => {
    if (selectedRecipients.includes(recipient)) {
      removeSelectedRecipients(recipient);
    } else {
      addSelectedRecipients(recipient);
    }
  };

  const handleChange = (event) => {
    console.log(event?.length);
    if (event?.target?.name) {
      const id = event.target.name.split('_')[1];
      toggleSelectedRecipients(parseInt(id));
    } else if (event?.action === 'selectAll') {
      selectAllIds(event.ids);
    } else if (event?.action === 'removeAll') {
      removeAllIds(event.ids);
    }
  };

  useEffect(()=>{
    fetchToken(api.url + `/alert-interns/findRecipient`)
    .then(({json}) => {
      setCollectivitiesUsersData(json);
    })
  }, [user])

  useEffect(()=>{
    setSelectedUser(selectedRecipients)
    // eslint-disable-next-line
  }, [selectedRecipients])

  if (collectivitiesUsersData?.status !== 'OK') return (<CircularProgress />) 
  return (<>
    {collectivitiesUsersData?.childUsers?.length > 0 &&
      <UserCheckboxAccordion 
        users={collectivitiesUsersData?.childUsers} 
        title={'Externe'} 
        action={'Sélectionnez les destinataires : '} 
        handleChange={handleChange} getRecipient={getRecipient}/>
    }

    {collectivitiesUsersData?.collectivityUsers?.length > 0 &&
      <UserCheckboxAccordion 
        users={collectivitiesUsersData?.collectivityUsers} 
        title={'Interne'} 
        action={'Envoyez à '} 
        handleChange={handleChange} getRecipient={getRecipient} selectedRecipients={selectedRecipients} />
    }

    {collectivitiesUsersData?.parentUsers?.length > 0 && 
      <UserCheckboxAccordion 
        users={collectivitiesUsersData?.parentUsers} 
        title={collectivitiesUsersData?.parentUsers[0]?.collectiviteName}
        action={'Sélectionnez les destinataires : '} 
        handleChange={handleChange} getRecipient={getRecipient}/>
    }
  </>);
 }

 const UserCheckboxAccordion = (props) => {
  const { users, title, action, handleChange, getRecipient } = props;
  const user = useSelector(state => state.account.user);
  const classes = useStyles();

  const nbChecked = users.filter(u => getRecipient()?.includes(u.id) && u?.id !== user?.id).length;
  const nbUsers = users.length;
  const userInList = ((users?.find(u => u.id === user?.id))?.id>0)?1:0;
  const selectAll = nbUsers === (nbChecked+(userInList?1:0));
  const undeterminate = nbChecked===0?false:!(selectAll) ;
  
  // console.log('nbUsers:', nbUsers,'nbChecked:', nbChecked,'('+(nbChecked+(userInList?1:0))+')','userInList:', userInList, 'selectAll:', selectAll, 'undeterminate:', undeterminate);

  const userNameCheckbox = (user) => {
    if (user?.name && user?.firstname)
    return (`${user?.name} ${user?.firstname}`);
    if (user?.name)
    return (`${user?.name}`);
    if (user?.firstname)
    return (`${user?.firstname}`);
    if (user?.username)
    return (`${user?.username}`);
    return ('')
  }

  if (!users || users?.length < 1) return null;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{action}</FormLabel>
            <FormGroup>

              <FormControlLabel control={
                <Checkbox 
                  checked={selectAll} 
                  indeterminate={undeterminate}
                  onChange={()=>handleChange(
                    selectAll?{
                      action: 'removeAll',
                      ids: users.filter(u => u?.id !== user?.id).map(u => u?.id)
                    }:{
                      action: 'selectAll',
                      ids: users.filter(u => u?.id !== user?.id).map(u => u?.id)
                    }
                  )} 
                  name={"user_all"}
                  key={"azeaze"}
                />
              }
              label={"Sélectionnez tous"}
              >
              {/* {userNameCheckbox(user)} */}
              </FormControlLabel>



              {users.map((item, key) => {
                if (item?.id === user?.id) return null;
                return (
                  <FormControlLabel key={'key_f_'+key}
                    control={
                      <Checkbox 
                        checked={Boolean(getRecipient(item?.id))} 
                        onChange={handleChange} 
                        name={"user_"+(item?.id || key)} 
                        key={'key_c_'+key}/>}
                    label={
                      <div >{userNameCheckbox(item)} 
                        <Typography  className={classes.end} style={{display: "inline"}}>- {item.collectiviteName}</Typography>
                      </div>}
                  />
                )}
              )}
            </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  )
 }